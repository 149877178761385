import DropdownLanguage from "./DropdownLanguage";
import HelpIcon from "@mui/icons-material/Help";
import HomeIcon from "@mui/icons-material/Home";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import React, { FC, useEffect } from "react";
import { IconButton } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentUser } from "../../../redux/selectors/authSelectors";
import { iconBasicColor } from "../../../styles/mui/theme";
import {
  handleNavigateToLogin,
  handleNavigateToPage,
  helpdeskPath,
} from "../../../utils/paths";
import { useIsMobile, useIsUserLoggedIn } from "../../../utils/hooks";
import * as styles from "../layout.module.scss";
import { selectFacilityInfo } from "../../../redux/selectors/userSelectors";
import { getPsychologicalLabInfo } from "../../../redux/actions/userActions";

interface Props {
  handleDrawerToggle?: () => void;
  showHelp?: boolean;
  showLoginPage?: boolean;
  adminPanel?: boolean;
}

const MainTopbar: FC<Props> = ({
  handleDrawerToggle,
  showHelp = true,
  showLoginPage = false,
  adminPanel = false,
}) => {
  const disptach = useDispatch();
  const isMobile = useIsMobile();
  const currentUser = useSelector(selectCurrentUser);
  const facilityInfo = useSelector(selectFacilityInfo);
  const loggedIn = useIsUserLoggedIn();

  const loggedContent = () => {
    return isMobile ? (
      <>
        <div className={styles.mobileTopbarItem}>
          <h4 className={adminPanel ? styles.topbarItem : undefined}>
            {currentUser &&
              `${currentUser?.firstName || ""} ${currentUser?.lastName || ""} (${currentUser.email})`}
            {!adminPanel &&
              <>
                <br />
                <label className={styles.topbarItemCompany}>{facilityInfo?.name}</label>
              </>}
          </h4>
        </div>
        <MenuRoundedIcon onClick={handleDrawerToggle} fontSize="large" />
      </>
    ) : (
      <div className={styles.topbarItem}>
        <h4 className={adminPanel ? styles.topbarItem : undefined}>
          {currentUser &&
            `${currentUser?.firstName || ""} ${currentUser?.lastName || ""} (${currentUser.email})`}
          {!adminPanel &&
            <>
              <br />
              <label className={styles.topbarItemCompany}>{facilityInfo?.name}</label>
            </>}
        </h4>
        {showHelp && (
          <IconButton onClick={() => handleNavigateToPage(helpdeskPath)}>
            <HelpIcon sx={[iconBasicColor]} />
          </IconButton>
        )}
      </div>
    );
  };

  const notLoggedContent = () => {
    return (
      <div className={styles.topbarMain}>
        {showHelp && <IconButton
          onClick={() => handleNavigateToPage(helpdeskPath)}
          sx={iconBasicColor}
        >
          <HelpIcon />
        </IconButton>}
        {showLoginPage && <IconButton
          onClick={() => handleNavigateToLogin()}
          sx={iconBasicColor}
        >
          <HomeIcon />
        </IconButton>}

        <div className={styles.topbarMainSeparator} />
        <DropdownLanguage />
      </div>
    );
  };

  useEffect(() => {
    if (loggedIn && !adminPanel && facilityInfo?.name === undefined) {
      disptach(getPsychologicalLabInfo());
    }
  }, [loggedIn]);


  return (
    <div className={styles.topbarMain}>
      {loggedIn ? loggedContent() : notLoggedContent()}
    </div>
  );
};

export default MainTopbar;

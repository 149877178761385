import { elementHover, fontFamilyName, tertiaryColor } from "../../../../styles/mui/theme";

const muiStylesDropdownLanguage = {
  dropdown: {
    "fontFamily": fontFamilyName,
    "width": "120px",
    "&.MuiInputBase-root": {
      "& .MuiSelect-select": {
        "fontFamily": fontFamilyName,
        "display": "flex",
        "alignItems": "center",
        "paddingRight": 0,
        "& .MuiListItemText-root": {
          fontFamily: fontFamilyName,
          marginLeft: "10px",
        },
      },
    },
    "&.MuiInputBase-root:before": {
      borderBottom: "transparent",
      fontFamily: fontFamilyName,
    },
    "&.MuiInputBase-root:after": {
      borderBottom: "transparent",
      fontFamily: fontFamilyName,
    },
    "&.MuiInputBase-root:hover:not(.Mui-disabled):before": {
      borderBottom: "transparent",
      fontFamily: fontFamilyName,
    },
    "&.MuiListItemText-root": { margin: 0, width: "50px", fontFamily: fontFamilyName },
    "&.MuiButtonBase-root-MuiMenuItem-root:hover": {
      fontFamily: fontFamilyName,
    },
  },
  dropdownRoot: {
    display: "flex",
    alignItems: "center",
    fontFamily: fontFamilyName,
  },
  item: {
    ":hover": {
      background: elementHover,
      fontFamily: fontFamilyName,
    },
    ":focus": {
      background: elementHover,
      fontFamily: fontFamilyName,
    },
    "&.MuiListItem-root, &.MuiMenuItem-root, &.MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover": {
      background: elementHover,
      fontFamily: fontFamilyName,
    },
  },
  icon: {
    minWidth: "0px",
  },
  text: {
    fontFamily: fontFamilyName,
    color: tertiaryColor,
    fontWeight: "700",
    fontSize: "14px",
  },
};

export default muiStylesDropdownLanguage;

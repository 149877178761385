import { RootState } from "../store";

export const selectResetPasswordLoading = (state: RootState) =>
  state.resetPassword.loading;

export const selectResetPasswordSuccess = (state: RootState) =>
  state.resetPassword.succeeded;

export const selectResetPasswordError = (state: RootState) =>
  state.resetPassword.error;

export const selectResetPasswordErrorMessage = (state: RootState) =>
  state.resetPassword.errorMessage;

export const selectResetPasswordEmailLoading = (state: RootState) =>
  state.resetPasswordEmail.loading;

export const selectResetPasswordEmailSuccess = (state: RootState) =>
  state.resetPasswordEmail.succeeded;

export const selectCurrentUser = (state: RootState) => state.auth.user;

export const selectCurrentUserId = (state: RootState) => state.auth.user?.id;

export const selectCurrentTheme = (state: RootState) => state.auth.theme;

export const selectAuthUserError = (state: RootState) => ({
  error: state.auth.error,
  details: state.auth.errorDetails,
  message: state.auth.errorMessage,
  userId: state.auth.expiredUserId,
});

export const selectUpdateAdminLab = (state: RootState) => ({
  error: state.auth.logAsUserError,
  loading: state.auth.logAsUserLoading,
  success: state.auth.logAsUserSuccess,
});

import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { useMediaQuery, useEventListener } from "usehooks-ts";
import { mobileBreakpointValuePx } from ".";
import { isLoggedIn } from "./auth";
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/selectors/i18nSelectors";
import { handleNavigateToPreviousPage } from "./paths";
import { optimisTheme } from "../styles/mui/optimisTheme";
import { defaultTheme } from "../styles/mui/defaultTheme";
import { selectCurrentTheme } from "../redux/selectors/authSelectors";

export const useIsMobile = (
  resolution: number = mobileBreakpointValuePx - 1
): boolean => useMediaQuery(`(max-width: ${resolution}px)`);

export const useIsUserLoggedIn = (): boolean => {
  const [loggedIn, setLoggedIn] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    async function checkLoginStatus() {
      setLoggedIn(await isLoggedIn());
    }

    void checkLoginStatus();
  }, []);

  return !!loggedIn;
};

export const useIsCapsLockOn = (): boolean => {
  const [capslockActive, setCapslockActive] = useState(false);

  const handleCapslock = (event: KeyboardEvent) => {
    if (!("getModifierState" in event)) {
      return;
    }

    setCapslockActive(event.getModifierState("CapsLock"));
  };

  useEventListener("keyup", handleCapslock);

  return capslockActive;
};

export const useIsUnder60yo = (birthdate: string): boolean => {
  const [examineeUnder60yo, setExamineeUnder60yo] = useState(false);

  useEffect(() => {
    const currentAgeExaminee = dayjs().diff(dayjs(birthdate), "year");
    if (currentAgeExaminee < 60) {
      setExamineeUnder60yo(true);
    } else {
      setExamineeUnder60yo(false);
    }
  }, [birthdate]);

  return examineeUnder60yo;
};

export const useIsUnsupportedDocLanguage = (): boolean => {
  const DOC_SUPPORTED_LANGUAGE_CODES = ["pl"];
  const currentLanguage = useSelector(selectLanguage);

  return !DOC_SUPPORTED_LANGUAGE_CODES.includes(currentLanguage);
};

/*
 * Hook handling navigation to a previous if page is not supported in the current language version
 * */
export const useUnsupportedDocPage = () => {
  const hideDoc = useIsUnsupportedDocLanguage();

  useEffect(() => {
    hideDoc && void handleNavigateToPreviousPage();
  }, [hideDoc]);
};

export const useCurrentTheme = () => {
  const currentTheme = useSelector(selectCurrentTheme);

  const getThemeProvider = () => {
    switch (currentTheme || "optimisTheme") {
      case "optimisTheme":
        return optimisTheme;
      case "defaultTheme":
        return defaultTheme;
    }
  }

  const getThemeName = () => {
    switch (currentTheme || "optimisTheme") {
      case "optimisTheme":
        return "optimisTheme";
      case "defaultTheme":
        return "defaultTheme";
    }
  }

  return { theme: getThemeProvider() || optimisTheme, themeName: getThemeName() || "optimisTheme" };
};
import * as styles from "../layout.module.scss";
import React, { FC, ReactNode, useEffect, useState } from "react";
import OptimisLogo from "../../../images/svg/logo/optimis-logo-big.svg";
import AltaLogo from "../../../images/svg/logo/alta-logo.svg";
import Footer from "../../../images/footer.png";
import MainTopbar from "../MainTopbar";
import { handleNavigateToLogin } from "../../../utils/paths";
import { IconButton } from "@mui/material";
import AppSnackbarProvider from "../../Common/Snackbars/UniversalSnackbar/SnackbarProvider";
import Notifier from "../../Common/Snackbars/UniversalSnackbar/Notifier";
import { useIsMobile } from "../../../utils/hooks";
import OptimisDiv100vh from "../Div100vh";
import packageJson from "../../../../package.json";

interface Props {
  children: ReactNode;
  showHelp?: boolean;
  showLoginPage?: boolean;
}

const OnboardingLayout: FC<Props> = ({ children, showHelp = true, showLoginPage = false }) => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const isMobile = useIsMobile();

  const handleToggleDrawer = () => setIsSideBarOpen(!isSideBarOpen);

  useEffect(() => setIsSideBarOpen(false), [isMobile]);

  return (
    <OptimisDiv100vh>
      <AppSnackbarProvider>
        <Notifier />
        <div className={styles.wrapper}>
          <div className={styles.topbar}>
            <div className={styles.topbarContent}>
              <IconButton onClick={handleNavigateToLogin}>
                <span>
                  <AltaLogo className={styles.topbarLogo} />
                </span>
              </IconButton>
              <div>
                <MainTopbar handleDrawerToggle={handleToggleDrawer} showHelp={showHelp} showLoginPage={showLoginPage} />
              </div>
            </div>
          </div>
          <div>
            <OptimisLogo className={styles.contentLogo} />
          </div>
          <div className={!isMobile ? styles.content : styles.contentMobile}>{children}</div>
          <div className={styles.footer}>
            <img src={Footer} alt="footer-img" />
          </div>
          <span className={styles.topBarVersion}>v{packageJson.version}</span>
        </div>
      </AppSnackbarProvider>
    </OptimisDiv100vh>
  );
};

export default OnboardingLayout;

import React, { FC } from "react";
import muiStylesDropdownLanguage from "./muiStylesDropdownLanguage";
import { useDispatch, useSelector } from "react-redux";
import { selectLanguage } from "../../../../redux/selectors/i18nSelectors";
import { setLocale } from "react-redux-i18n";
import {
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  SelectChangeEvent,
} from "@mui/material";
import { useCookies } from "react-cookie";

interface Props {
  readOnly?: boolean | undefined;
}

const DropdownLanguage: FC<Props> = ({ readOnly }) => {
  const CookieValidYears = 3;
  const currentLanguage = useSelector(selectLanguage);
  const dispatch = useDispatch();
  const [cookies, setCookies] = useCookies(["langName"]);
  const daysToExpireCookie = new Date(
    new Date().setFullYear(new Date().getFullYear() + CookieValidYears)
  );

  const onChangeLanguage = (event: SelectChangeEvent<unknown>) => {
    const newLang = event.target.value as string;
    dispatch(setLocale(newLang));
    setCookies("langName", newLang, {
      path: "/",
      expires: daysToExpireCookie,
    });
  }

  const getCurrentLanguage = () => {
    const currLang = cookies.langName !== undefined && cookies.langName !== currentLanguage ? cookies.langName : currentLanguage;
    if (currLang !== currentLanguage) {
      dispatch(setLocale(currLang));
    }
    return currLang as string;
  }

  return (
    <FormControl>
      <Select
        disabled={readOnly}
        key={`language-${getCurrentLanguage()}`}
        defaultValue={getCurrentLanguage()}
        variant="standard"
        sx={muiStylesDropdownLanguage.dropdown}
        onChange={onChangeLanguage}
      >
        <MenuItem key={`dropdown-item-pl`} value="pl" sx={muiStylesDropdownLanguage.item}>
          <ListItemText primary="Polski" sx={muiStylesDropdownLanguage.icon} />
        </MenuItem>
        <MenuItem key={`dropdown-item-en`} value="en" sx={muiStylesDropdownLanguage.item}>
          <ListItemText primary="English" sx={muiStylesDropdownLanguage.icon} />
        </MenuItem>
      </Select>
    </FormControl >
  );
};

export default DropdownLanguage;

import React, { FC, ReactNode } from "react";
import { useIsMobile } from "../../../utils/hooks";
import { use100vh } from "react-div-100vh";

interface Props {
  children: ReactNode;
}

const OptimisDiv100vh: FC<Props> = ({ children }) => {
  /*
   * Due to Gatsby build optimization, dynamically adding or removing a parent <div> in `/pages`
   * causes unexpected behavior in the structure of pre-generated HTML.
   *
   * The solution implemented below focuses on setting `height` instead.
   */
  const isMobile = useIsMobile();
  const height = use100vh();

  return (
    <div style={{ height: isMobile && height ? height : "inherit" }}>
      {children}
    </div>
  );
};

export default OptimisDiv100vh;

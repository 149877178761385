import { createTheme } from "@mui/material";
import { mobileBreakpointValuePx, selectedTheme } from "../../utils";
import { treeItemClasses } from "@mui/x-tree-view/TreeItem";

interface ThemeValueType {
  propName: string;
  propValue: string;
}

const themeValuesDefaultArray: ThemeValueType[] = [
  { propName: "dataTextColor", propValue: "#112140" },

  { propName: "fontFamily", propValue: "Lato" },

  { propName: "primaryColor", propValue: "#F29400" },
  { propName: "primaryColorHover", propValue: "#D17700" },
  { propName: "primaryColorFocused", propValue: "#FFA126" },
  { propName: "primaryColorDisabled", propValue: "#F294004D" },

  { propName: "secondaryColor", propValue: "#ffffff" },
  { propName: "secondaryColorHover", propValue: "#C8D4E2" },
  { propName: "secondaryColorFocused", propValue: "#EAEFF5" },
  { propName: "secondaryColorDisabled", propValue: "#ffffff" },
  { propName: "secondaryColorSelected", propValue: "#C8D4E2" },

  { propName: "tertiaryColor", propValue: "#0A2A4E" },
  { propName: "tertiaryColorHover", propValue: "#08468A" },
  { propName: "tertiaryColorFocused", propValue: "#002044" },
  { propName: "tertiaryColorDisabled", propValue: "#0a2a4e4d" },
  { propName: "tertiaryColorErrorHover", propValue: "#c91231" },
  { propName: "tertiaryColorErrorDisabled", propValue: "#AC0D284D" },
  { propName: "tertiaryColorErrorFocus", propValue: "#c41022" },

  { propName: "fifthColor", propValue: "#FFFFFF" },
  { propName: "fifthColorHover", propValue: "#FFFFFF" },
  { propName: "fifthColorFocused", propValue: "#FFFFFF" },
  { propName: "fifthColorDisabled", propValue: "#FFFFFF" },

  { propName: "quaternaryColor", propValue: "#FFFFFF" },
  { propName: "quaternaryColorHover", propValue: "#EEEEEE" },
  { propName: "quaternaryColorFocused", propValue: "#D5D5D5" },
  { propName: "quaternaryColorDisabled", propValue: "#FFFFFF" },

  { propName: "activeElementBlue", propValue: "#0055FF" },
  { propName: "activeElementYellow", propValue: "#FFBB00" },
  { propName: "warningColor", propValue: "#ac0d28" },
  { propName: "failedValidationColor", propValue: "#B9B9B9" },
  { propName: "successColor", propValue: "#239A00" },
  { propName: "elementHover", propValue: "#EAEFF5" },

  { propName: "fontColorPrimary", propValue: "#0a2a4e" },
  { propName: "fontColorSecondary", propValue: "#ffffff" },
  { propName: "fontColorInactive", propValue: "rgba(17, 33, 64, 0.3)" },
  { propName: "fontColorWarning", propValue: "#FF8A00" },
  { propName: "fontColorInput", propValue: "#11214099" },
  { propName: "fontColorSnackbar", propValue: "#303030" },
  { propName: "fontColorQuaternaryButton", propValue: "#112140" },
  { propName: "fontColorBody", propValue: "#112140" },

  { propName: "borderColor", propValue: "#0a2a4e" },
  { propName: "borderColorDisabled", propValue: "rgba(10, 42, 78, 0.3)" },
  { propName: "borderBottomColor", propValue: "#FFFFFF" },
  { propName: "borderColorTabInactive", propValue: "#CCCCCC" },

  { propName: "tabInactiveColor", propValue: "#F2F2F2" },
  { propName: "dialogBackgroundColor", propValue: "#4E4E4E66" },

  // border radius sizes
  { propName: "borderRadiusButton", propValue: "6px" },
  { propName: "borderRadiusCard", propValue: "17px" },
  { propName: "borderRadiusDropdown", propValue: "4px" },
  { propName: "borderRadiusInput", propValue: "4px" },
  { propName: "borderRadiusPopup", propValue: "12px" },
  { propName: "borderRadiusTab", propValue: "6px 6px 0px 0px" },

  // shadows
  { propName: "shadowButton", propValue: "0px 4px 5px #11214029" },
  { propName: "shadowCard", propValue: "0px 3px 10px #11214040" },
  { propName: "shadowSnackbar", propValue: "0px 3px 6px #11214029" },

  // inputs
  { propName: "inputBorder", propValue: "#747474" },
  { propName: "inputBorderFocused", propValue: "#08468A" },
  { propName: "inputBorderHovered", propValue: "#303030" },
  { propName: "inputBorderDisabled", propValue: "#1121401F" },
  { propName: "inputBorderError", propValue: "#AC0D28" },
];


const getThemeValues = (propName: string) => {
  return themeValuesDefaultArray.find(el => el.propName === propName)?.propValue || "";
}

// colors
export const dataTextColor = getThemeValues("dataTextColor");

export const fontFamilyName = getThemeValues("fontFamilyName");

export const primaryColor = getThemeValues("primaryColor");
export const primaryColorHover = getThemeValues("primaryColorHover");
export const primaryColorFocused = getThemeValues("primaryColorFocused");
export const primaryColorDisabled = getThemeValues("primaryColorDisabled");

export const secondaryColor = getThemeValues("secondaryColor");
export const secondaryColorHover = getThemeValues("secondaryColorHover");
export const secondaryColorFocused = getThemeValues("secondaryColorFocused");
export const secondaryColorDisabled = getThemeValues("secondaryColorDisabled");
export const secondaryColorSelected = getThemeValues("secondaryColorSelected");

export const tertiaryColor = getThemeValues("tertiaryColor");
export const tertiaryColorHover = getThemeValues("tertiaryColorHover");
export const tertiaryColorFocused = getThemeValues("tertiaryColorFocused");
export const tertiaryColorDisabled = getThemeValues("tertiaryColorDisabled");
export const tertiaryColorErrorHover = getThemeValues("tertiaryColorErrorHover");
export const tertiaryColorErrorDisabled = getThemeValues("tertiaryColorErrorDisabled");
export const tertiaryColorErrorFocus = getThemeValues("tertiaryColorErrorFocus");

export const quaternaryColor = getThemeValues("quaternaryColor");
export const quaternaryColorHover = getThemeValues("quaternaryColorHover");
export const quaternaryColorFocused = getThemeValues("quaternaryColorFocused");
export const quaternaryColorDisabled = getThemeValues("quaternaryColorDisabled");

export const fifthColor = getThemeValues("fifthColor");
export const fifthColorHover = getThemeValues("fifthColorHover");
export const fifthColorFocused = getThemeValues("fifthColorFocused");
export const fifthColorDisabled = getThemeValues("fifthColorDisabled");

export const activeElementBlue = getThemeValues("activeElementBlue");
export const activeElementYellow = getThemeValues("activeElementYellow");
export const warningColor = getThemeValues("warningColor");
export const failedValidationColor = getThemeValues("failedValidationColor");
export const successColor = getThemeValues("successColor");
export const elementHover = getThemeValues("elementHover");

export const fontColorPrimary = getThemeValues("fontColorPrimary");
export const fontColorSecondary = getThemeValues("fontColorSecondary");
export const fontColorInactive = getThemeValues("fontColorInactive");
export const fontColorWarning = getThemeValues("fontColorWarning");
export const fontColorInput = getThemeValues("fontColorInput");
export const fontColorSnackbar = getThemeValues("fontColorSnackbar");
export const fontColorQuaternaryButton = getThemeValues("fontColorQuaternaryButton");
export const fontColorBody = getThemeValues("fontColorBody");

export const borderColor = getThemeValues("borderColor");
export const borderColorDisabled = getThemeValues("borderColorDisabled");
export const borderBottomColor = getThemeValues("borderBottomColor");
export const borderColorTabInactive = getThemeValues("borderColorTabInactive");

export const tabInactiveColor = getThemeValues("tabInactiveColor");
export const dialogBackgroundColor = getThemeValues("dialogBackgroundColor");

// border radius sizes
export const borderRadiusButton = getThemeValues("borderRadiusButton");
export const borderRadiusCard = getThemeValues("borderRadiusCard");
export const borderRadiusDropdown = getThemeValues("borderRadiusDropdown");
export const borderRadiusInput = getThemeValues("borderRadiusInput");
export const borderRadiusPopup = getThemeValues("borderRadiusPopup");
export const borderRadiusTab = getThemeValues("borderRadiusTab");

// shadows
export const shadowButton = getThemeValues("shadowButton");
export const shadowCard = getThemeValues("shadowCard");
export const shadowSnackbar = getThemeValues("shadowSnackbar");

// inputs
export const inputBorder = getThemeValues("inputBorder");
export const inputBorderFocused = getThemeValues("inputBorderFocused");
export const inputBorderHovered = getThemeValues("inputBorderHovered");
export const inputBorderDisabled = getThemeValues("inputBorderDisabled");
export const inputBorderError = getThemeValues("inputBorderError");

// typography
export const buttonTypography = {
  textTransform: "uppercase",
  fontWeight: "700",
  fontSize: "16px",
  letterSpacing: "0px",
  lineHeight: "22px",
  marginBottom: "10px",
  marginRight: "10px",
};

export const defaultTheme = createTheme({
  spacing: 4,
  typography: {
    fontFamily: "Lato",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: "Lato";
      }
    `,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: mobileBreakpointValuePx,
      lg: 1024,
      xl: 1920,
    },
  },
});

export const styledTreeItemRoot = {
  color: primaryColorHover,
  position: "relative",
};

export const stylesRichTreeView = {
  height: "fit-content",
  flexGrow: 1,
  width: "100%",
  overflowY: "auto",
  fontFamily: "Lato",
};

export const styledTreeItemLabelText = {
  color: "inherit",
  fontSize: "17px",
  padding: "5px",
};

export const styledTreeItem2Label = {
  display: "flex",
  alignItems: "center",
  [`&.Mui-expanded `]: {
    "&:hover": {
      backgroundColor: "red",
      color: "green",
    },
  }
}

export const styledBox = {
  mr: 1,
  fontSize: "1.6rem",
};


// color for icon
export const iconBasicColor = {
  color: tertiaryColor,
};

export const iconSecondaryColor = {
  color: secondaryColor,
};

export const iconEditColor = {
  color: primaryColor,
};

export const linkStyle = {
  textDecoration: "none",
  color: "inherit",
};

// Base form input styling
export const commonFormBase = {
  input: {
    "fontFamily": fontFamilyName,
    "width": "315px",
    "height": "56px",
    "& label": {
      "fontFamily": fontFamilyName,
      "color": inputBorder,
      "&.Mui-focused": {
        color: inputBorderFocused,
      },
    },
    "&.MuiFormControlLabel-root, .MuiFormControlLabel-label": {
      fontFamily: fontFamilyName,
    },
    "& .MuiOutlinedInput-root": {
      "fontFamily": fontFamilyName,
      "&.Mui-focused fieldset": {
        borderColor: inputBorderFocused,
        fontFamily: fontFamilyName,
      },
      "& fieldset": {
        borderColor: inputBorder,
        borderWidth: "2px",
      },
      "&:focus fieldset": {
        borderColor: inputBorderFocused,
      },
      "&:hover fieldset": {
        borderColor: inputBorderHovered,
      },
      "&:disabled fieldset": {
        borderColor: inputBorderDisabled,
      },
    },
  },
  passwordVisibility: {
    color: tertiaryColor,
  },
};

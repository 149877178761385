import { RootState } from "../store";

export const selectFacilityInfo = (state: RootState) =>
  state.userPsychologicalLab.psychologicalLabInfo;

export const selectUserChangePasswordLoading = (state: RootState) => {
  return state.userChangePassword.loading;
};

export const selectUserChangePasswordSuccess = (state: RootState) => {
  return state.userChangePassword.succeeded;
};

export const selectUserChangePinLoading = (state: RootState) => {
  return state.userChangePin.loading;
};

export const selectUserChangePinSuccess = (state: RootState) => {
  return state.userChangePin.succeeded;
};

export const selectUserChangePasswordExpiredSuccess = (state: RootState) => {
  return state.userChangePasswordExpired.succeeded;
};
